import { LockIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Container,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Input,
  ChakraProvider,
  Heading,
} from "@chakra-ui/react";
import { useState } from "react";
import { useAppDispatch } from "../hooks/redux-hooks";
import { login } from "../slices/authSlice";
import { ColorModeSwitcher } from "../ColorModeSwitcher";
import { showNotification, NotificationType } from "../slices/notificationSlice";

const Login = () => {
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const handleLogin = async () => {
    // Handle login logic here
    if (email && password) {
      try {
        await dispatch(
          login({
            email,
            password,
          })
        ).unwrap();
      } catch (e) {
        console.error(e);
      }
    } else {
      dispatch(
        showNotification({
          message: "Please provide email and password",
          type: NotificationType.Error,
        })
      );
    }
  };

  return (
    <ChakraProvider>
      <ColorModeSwitcher justifySelf="flex-end" />
      <Center minHeight="100vh">
        <Box p={8} maxWidth="500px" borderWidth={1} borderRadius={8} boxShadow="lg">
          <Container maxW="xs">
            <Flex direction="column" align="center" mt={20}>
              <LockIcon w={8} h={8} marginBottom="40px" />
              <Heading fontSize="2xl">Login Page</Heading>
              <Box mt={4} w="100%">
                <FormControl isRequired>
                  <FormLabel>Email Address</FormLabel>
                  <Input id="email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                </FormControl>
                <FormControl isRequired mt={4}>
                  <FormLabel>Password</FormLabel>
                  <Input id="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                </FormControl>
                <Button colorScheme="blue" mt={6} width="full" onClick={handleLogin}>
                  Login
                </Button>
              </Box>
            </Flex>
          </Container>
        </Box>
      </Center>
    </ChakraProvider>
  );
};

export default Login;
