export const BACKEND_BASE_URL = "https://customer-dashboard-backend.nihat-b73.workers.dev";

export type ErrorResponse = {
  message: string;
};

export enum Roles {
  Admin = "ADMIN",
  User = "USER",
}

export const SpecialOffer = {
  name: "Special Offer",
  bundleId: "com.ck.tripletiles.so",
  price: 1.99,
  coins: 1000,
  undoPowerUp: 1,
  magnetPowerUp: 1,
  shufflePowerUp: 1,
  timeFreezePowerUp: 1,
  lazerPowerUp: 1,
  extraTimePowerUp: 1,
};

export const StarterPack = {
  name: "Starter Pack",
  bundleId: "com.ck.tripletiles.sp",
  price: 3.99,
  coins: 2000,
  undoPowerUp: 1,
  magnetPowerUp: 1,
  shufflePowerUp: 1,
  timeFreezePowerUp: 1,
  lazerPowerUp: 1,
  extraTimePowerUp: 1,
};

export const PremiumPack = {
  name: "Premium Pack",
  bundleId: "com.ck.tripletiles.pp",
  price: 8.99,
  coins: 5000,
  undoPowerUp: 1,
  magnetPowerUp: 1,
  shufflePowerUp: 1,
  timeFreezePowerUp: 1,
  lazerPowerUp: 1,
  extraTimePowerUp: 1,
};

export const MegaPack = {
  name: "Mega Pack",
  bundleId: "com.ck.tripletiles.mp",
  price: 19.99,
  coins: 12000,
  undoPowerUp: 2,
  magnetPowerUp: 2,
  shufflePowerUp: 2,
  timeFreezePowerUp: 2,
  lazerPowerUp: 2,
  extraTimePowerUp: 2,
};

export const DeluxePack = {
  name: "Deluxe Pack",
  bundleId: "com.ck.tripletiles.deluxepack",
  price: 39.99,
  coins: 20000,
  undoPowerUp: 6,
  magnetPowerUp: 6,
  shufflePowerUp: 6,
  timeFreezePowerUp: 6,
  lazerPowerUp: 6,
  extraTimePowerUp: 6,
};

export const PrestigePack = {
  name: "Prestige Pack",
  bundleId: "com.ck.tripletiles.prestigepack",
  price: 79.99,
  coins: 40000,
  undoPowerUp: 15,
  magnetPowerUp: 15,
  shufflePowerUp: 15,
  timeFreezePowerUp: 15,
  lazerPowerUp: 15,
  extraTimePowerUp: 15,
};

export const LegendaryPack = {
  name: "Legendary Pack",
  bundleId: "com.ck.tripletiles.legendarypack",
  price: 99.99,
  coins: 60000,
  undoPowerUp: 30,
  magnetPowerUp: 30,
  shufflePowerUp: 30,
  timeFreezePowerUp: 30,
  lazerPowerUp: 30,
  extraTimePowerUp: 30,
};

export const SpecialCoinPack = {
  name: "Special Coin Pack",
  bundleId: "com.ck.tripletiles.specialcoin",
  price: 0.99,
  coins: 1000,
  undoPowerUp: 0,
  magnetPowerUp: 0,
  shufflePowerUp: 0,
  timeFreezePowerUp: 0,
  lazerPowerUp: 0,
  extraTimePowerUp: 0,
};

export const MiniCoinPack = {
  name: "Mini Coin Pack",
  bundleId: "com.ck.tripletiles.minicoin",
  price: 1.99,
  coins: 1000,
  undoPowerUp: 0,
  magnetPowerUp: 0,
  shufflePowerUp: 0,
  timeFreezePowerUp: 0,
  lazerPowerUp: 0,
  extraTimePowerUp: 0,
};

export const SmallCoinPack = {
  name: "Small Coin Pack",
  bundleId: "com.ck.tripletiles.smallcoinpack",
  price: 3.99,
  coins: 2250,
  undoPowerUp: 0,
  magnetPowerUp: 0,
  shufflePowerUp: 0,
  timeFreezePowerUp: 0,
  lazerPowerUp: 0,
  extraTimePowerUp: 0,
};

export const MediumCoinPack = {
  name: "Medium Coin Pack",
  bundleId: "com.ck.tripletiles.mediumcoinpack",
  price: 8.99,
  coins: 5500,
  undoPowerUp: 0,
  magnetPowerUp: 0,
  shufflePowerUp: 0,
  timeFreezePowerUp: 0,
  lazerPowerUp: 0,
  extraTimePowerUp: 0,
};

export const LargeCoinPack = {
  name: "Large Coin Pack",
  bundleId: "com.ck.tripletiles.largecoinpack",
  price: 14.99,
  coins: 10000,
  undoPowerUp: 0,
  magnetPowerUp: 0,
  shufflePowerUp: 0,
  timeFreezePowerUp: 0,
  lazerPowerUp: 0,
  extraTimePowerUp: 0,
};

export const XLCoinPack = {
  name: "XL Coin Pack",
  bundleId: "com.ck.tripletiles.xlcoinpack",
  price: 29.99,
  coins: 25000,
  undoPowerUp: 0,
  magnetPowerUp: 0,
  shufflePowerUp: 0,
  timeFreezePowerUp: 0,
  lazerPowerUp: 0,
  extraTimePowerUp: 0,
};

export const MegaCoinPack = {
  name: "Mega Coin Pack",
  bundleId: "com.ck.tripletiles.megacoinpack",
  price: 99.99,
  coins: 100000,
  undoPowerUp: 0,
  magnetPowerUp: 0,
  shufflePowerUp: 0,
  timeFreezePowerUp: 0,
  lazerPowerUp: 0,
  extraTimePowerUp: 0,
};

export const OfferRoad1 = {
  name: "Offer Road 1",
  bundleId: "com.ck.tripletiles.offerroad1",
  price: 0.99,
  coins: 1000,
  undoPowerUp: 0,
  magnetPowerUp: 0,
  shufflePowerUp: 0,
  timeFreezePowerUp: 0,
  lazerPowerUp: 0,
  extraTimePowerUp: 1,
};

export const OfferRoad2 = {
  name: "Offer Road 2",
  bundleId: "com.ck.tripletiles.offerroad2",
  price: 2.99,
  coins: 3000,
  undoPowerUp: 0,
  magnetPowerUp: 0,
  shufflePowerUp: 1,
  timeFreezePowerUp: 0,
  lazerPowerUp: 1,
  extraTimePowerUp: 0,
};

export const OfferRoad3 = {
  name: "Offer Road 3",
  bundleId: "com.ck.tripletiles.offerroad3",
  price: 4.99,
  coins: 5000,
  undoPowerUp: 0,
  magnetPowerUp: 1,
  shufflePowerUp: 0,
  timeFreezePowerUp: 0,
  lazerPowerUp: 1,
  extraTimePowerUp: 1,
};

export const ChooseOneBronze = {
  name: "Choose One Bronze",
  bundleId: "com.ck.tripletiles.chooseonebronze",
  price: 1.99,
  coins: 1000,
  undoPowerUp: 0,
  magnetPowerUp: 1,
  shufflePowerUp: 1,
  timeFreezePowerUp: 0,
  lazerPowerUp: 0,
  extraTimePowerUp: 0,
};

export const ChooseOneSilver = {
  name: "Choose One Silver",
  bundleId: "com.ck.tripletiles.chooseonesilver",
  price: 3.99,
  coins: 2000,
  undoPowerUp: 1,
  magnetPowerUp: 1,
  shufflePowerUp: 1,
  timeFreezePowerUp: 1,
  lazerPowerUp: 0,
  extraTimePowerUp: 0,
};

export const ChooseOneGold = {
  name: "Choose One Gold",
  bundleId: "com.ck.tripletiles.chooseonegold",
  price: 8.99,
  coins: 5000,
  undoPowerUp: 2,
  magnetPowerUp: 3,
  shufflePowerUp: 2,
  timeFreezePowerUp: 2,
  lazerPowerUp: 0,
  extraTimePowerUp: 0,
};

export const ChooseOneAll = {
  name: "Choose One All",
  bundleId: "com.ck.tripletiles.chooseoneall",
  price: 9.99,
  coins: 8000,
  undoPowerUp: 3,
  magnetPowerUp: 4,
  shufflePowerUp: 4,
  timeFreezePowerUp: 3,
  lazerPowerUp: 0,
  extraTimePowerUp: 0,
};

export const BundleIdPackageNameMap = {
  "com.ck.tripletiles.so": "Special Offer",
  "com.ck.tripletiles.sp": "Starter Pack",
  "com.ck.tripletiles.pp": "Premium Pack",
  "com.ck.tripletiles.mp": "Mega Pack",
  "com.ck.tripletiles.deluxepack": "Deluxe Pack",
  "com.ck.tripletiles.prestigepack": "Prestige Pack",
  "com.ck.tripletiles.legendarypack": "Legendary Pack",
  "com.ck.tripletiles.specialcoin": "Special Coin Pack",
  "com.ck.tripletiles.minicoin": "Mini Coin Pack",
  "com.ck.tripletiles.smallcoinpack": "Small Coin Pack",
  "com.ck.tripletiles.mediumcoinpack": "Medium Coin Pack",
  "com.ck.tripletiles.largecoinpack": "Large Coin Pack",
  "com.ck.tripletiles.xlcoinpack": "XL Coin Pack",
  "com.ck.tripletiles.megacoinpack": "Mega Coin Pack",
  "com.ck.tripletiles.offerroad1": "Offer Road 1",
  "com.ck.tripletiles.offerroad2": "Offer Road 2",
  "com.ck.tripletiles.offerroad3": "Offer Road 3",
  "com.ck.tripletiles.chooseonebronze": "Choose One Bronze",
  "com.ck.tripletiles.chooseonesilver": "Choose One Silver",
  "com.ck.tripletiles.chooseonegold": "Choose One Gold",
  "com.ck.tripletiles.chooseoneall": "Choose One All",
};
