import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../api/axiosInstance";
import { AxiosError } from "axios";
import { ErrorResponse } from "../constants";

type UserInfo = {
  id: string;
  name: string;
  surname: string;
  email: string;
  roles: string[];
};

type UserApiState = {
  users: UserInfo[];
  status: "idle" | "loading" | "failed";
  error: string | null;
};

const initialState: UserApiState = {
  users: [],
  status: "idle",
  error: null,
};

export const getUsers = createAsyncThunk("users/getUsers", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get("/users", {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")!).token}`,
      },
    });

    return response.data;
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      const errorResponse = error.response.data;

      return rejectWithValue(errorResponse);
    }

    throw error;
  }
});

export const addUser = createAsyncThunk(
  "users/addUser",
  async (
    {
      name,
      surname,
      email,
      password,
      roles,
    }: { name: string; surname: string; email: string; password: string; roles: string[] },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(
        "/users",
        { name, surname, email, password, roles },
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")!).token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);

export const updateUser = createAsyncThunk(
  "users/updateUser",
  async ({ id, roles }: { id: string; roles: string[] }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`/users/${id}`, roles, {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")!).token}`,
        },
      });
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);

export const updateUserPassword = createAsyncThunk(
  "users/updateUserPassword",
  async (
    { id, currentPassword, newPassword }: { id: string; currentPassword: string; newPassword: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.put(
        `/users/${id}/password`,
        { currentPassword: currentPassword, newPassword: newPassword },
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")!).token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);

export const deleteUser = createAsyncThunk("users/deleteUser", async (userId: string, { rejectWithValue }) => {
  try {
    await axiosInstance.delete(`/users/${userId}`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")!).token}`,
      },
    });
    return userId;
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      const errorResponse = error.response.data;

      return rejectWithValue(errorResponse);
    }

    throw error;
  }
});

export const fetchUserActions = createAsyncThunk(
  "users/fetchUserActions",
  async (
    { table, offset, limit, userId }: { table: string; offset: number; limit: number; userId: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(
        `/users/user-actions`,
        { table: table, offset: offset, limit: limit, userId: userId },
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")!).token}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        return rejectWithValue([]);
      }

      throw error;
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getUsers.fulfilled, (state, action: PayloadAction<UserInfo[]>) => {
        state.status = "idle";
        state.users = action.payload;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.status = "failed";
        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || "Retrieving users failed";
        } else {
          state.error = action.error.message || "Retrieving users failed";
        }
      })
      .addCase(addUser.fulfilled, (state, action: PayloadAction<UserInfo>) => {
        state.users.push(action.payload);
      })
      .addCase(addUser.rejected, (state, action) => {
        state.status = "failed";
        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || "Adding user failed";
        } else {
          state.error = action.error.message || "Adding user failed";
        }
      })
      .addCase(updateUser.fulfilled, (state, action: PayloadAction<UserInfo>) => {
        state.status = "idle";
        state.users = state.users.map((user) => {
          if (user.id === action.payload.id) {
            return action.payload;
          }
          return user;
        });
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.status = "failed";
        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || "Update user failed";
        } else {
          state.error = action.error.message || "Update user failed";
        }
      })
      .addCase(deleteUser.fulfilled, (state, action: PayloadAction<string>) => {
        state.users = state.users.filter((user) => user.id !== action.payload);
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.status = "failed";
        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || "Delete user failed";
        } else {
          state.error = action.error.message || "Deleting user failed";
        }
      })
      .addCase(fetchUserActions.rejected, (state, action) => {
        state.status = "failed";
        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || "Fetching user actions failed1";
        } else {
          state.error = action.error.message || "Fetching user actions failed2";
        }
      });
  },
});

export default userSlice.reducer;
