import React from "react";
import { Box, Button, Flex, IconButton } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { MdHome } from "react-icons/md";

const AdminNavbar = () => {
  const navigate = useNavigate();

  return (
    <Box bg="gray.800" color="white" p={4} mb={4}>
      <Flex justifyContent="space-between" alignItems="center">
        <IconButton aria-label="Home Page" icon={<MdHome />} onClick={() => navigate("/")} />
        <Button variant="ghost" color="white" onClick={() => navigate("/admin-dashboard")}>
          Admin Dashboard
        </Button>
        <Button variant="ghost" color="white" onClick={() => navigate("/user-settings")}>
          User Settings
        </Button>
      </Flex>
    </Box>
  );
};

export default AdminNavbar;
