import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Heading,
  Image,
  ChakraProvider,
  Flex,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import { getUser, logout } from "../slices/authSlice";
import { updateUserPassword } from "../slices/userSlice";
import { useNavigate } from "react-router-dom";
import { ColorModeSwitcher } from "../ColorModeSwitcher";
import AdminNavbar from "./AdminNavBar"; // Import Admin Navbar
import { Roles } from "../constants";
import { SettingsIcon } from "@chakra-ui/icons"; // Import Settings Icon
import { showNotification, NotificationType } from "../slices/notificationSlice";

const Home = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const basicUserInfo = useAppSelector((state) => state.auth.basicUserInfo);
  const userProfileInfo = useAppSelector((state) => state.auth.userProfileData);

  // Modal state for password change
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  useEffect(() => {
    if (basicUserInfo) {
      dispatch(getUser(basicUserInfo.id));
    }
  }, [basicUserInfo, dispatch]);

  const handleLogout = async () => {
    await dispatch(logout());
    navigate("/login");
  };

  const handleMatchHotel = () => {
    navigate("/match-hotel");
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handlePasswordChange = async () => {
    // Control all the fields are filled
    if (!currentPassword || !newPassword || !confirmPassword) {
      dispatch(
        showNotification({
          message: "Please fill all the fields!",
          type: NotificationType.Error,
        })
      );
      return;
    }

    // Add logic to handle password change
    if (newPassword !== confirmPassword) {
      dispatch(
        showNotification({
          message: "New passwords do not match!",
          type: NotificationType.Error,
        })
      );
      return;
    }

    await dispatch(
      updateUserPassword({
        id: basicUserInfo!.id,
        currentPassword: currentPassword,
        newPassword: newPassword,
      })
    );
    // Add API call to update the password here
    setIsModalOpen(false); // Close the modal after submission
  };

  return (
    <ChakraProvider>
      {basicUserInfo!.roles.some((role: string) => role === Roles.Admin) && <AdminNavbar />}
      <Flex direction="column" p={4}>
        <Flex justifyContent="space-between" alignItems="center" mb={4}>
          <Heading as="h1" size="xl">
            Home Page
          </Heading>
          <Flex alignItems="center">
            <Heading as="h4" size="md" textAlign="center" mx={4}>
              Welcome: {userProfileInfo?.name}
            </Heading>
            <IconButton
              icon={<SettingsIcon />}
              aria-label="Settings"
              colorScheme="teal"
              ml={4}
              onClick={handleOpenModal}
            />
            <Button colorScheme="teal" ml={4} onClick={handleLogout}>
              Logout
            </Button>
            <ColorModeSwitcher alignSelf="flex-end" />
          </Flex>
        </Flex>
        <Flex justifyContent="flex-start" mt={8}>
          <Box
            p={4}
            borderWidth={1}
            borderRadius="lg"
            boxShadow="md"
            cursor="pointer"
            onClick={handleMatchHotel}
            textAlign="center"
            _hover={{ boxShadow: "lg" }}
          >
            <Image
              objectFit="cover"
              src={require("../assets/T_MatchHotel_Logo_00.png")} // Replace with the actual path to your game icon
              alt="Match Hotel"
              boxSize="100px"
              mx="auto"
            />
            <Heading as="h2" size="lg" mt={4}>
              Match Hotel
            </Heading>
          </Box>
        </Flex>

        {/* Modal for changing password */}
        <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Change Password</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl mb={4}>
                <FormLabel>Current Password</FormLabel>
                <Input
                  type="password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  placeholder="Enter current password"
                />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>New Password</FormLabel>
                <Input
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  placeholder="Enter new password"
                />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>Confirm New Password</FormLabel>
                <Input
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirm new password"
                />
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="teal" mr={3} onClick={handlePasswordChange}>
                Change Password
              </Button>
              <Button onClick={handleCloseModal}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
    </ChakraProvider>
  );
};

export default Home;
