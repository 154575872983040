import React, { useEffect, useState } from "react";
import {
  ChakraProvider,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Box,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Input,
  VStack,
  FormControl,
  FormLabel,
  Text,
  CheckboxGroup,
  Checkbox,
  Stack,
  Heading,
} from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import { getUsers, deleteUser, updateUser, addUser } from "../slices/userSlice";
import { showNotification, NotificationType } from "../slices/notificationSlice";
import AdminNavbar from "./AdminNavBar";
import { Roles } from "../constants";
import { useNavigate } from "react-router-dom";

const UserSettings = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const users = useAppSelector((state) => state.users.users);
  const basicUserInfo = useAppSelector((state) => state.auth.basicUserInfo);

  // State for Delete Modal
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const [userToDelete, setUserToDelete] = useState<any>(null);

  // State for Update Roles Modal
  const { isOpen: isUpdateOpen, onOpen: onUpdateOpen, onClose: onUpdateClose } = useDisclosure();
  const [userToUpdate, setUserToUpdate] = useState<any>(null);
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);

  // State for Add User Modal
  const { isOpen: isAddUserOpen, onOpen: onAddUserOpen, onClose: onAddUserClose } = useDisclosure();
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newUserRoles, setNewUserRoles] = useState<string[]>([]);

  const [lastAddedUser, setLastAddedUser] = useState<any>(null);

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  // Handle Delete Button Click
  const handleDeleteClick = (user: any) => {
    setUserToDelete(user);
    onDeleteOpen();
  };

  // Confirm Delete
  const confirmDelete = () => {
    if (userToDelete) {
      dispatch(deleteUser(userToDelete.id));
      onDeleteClose();
    }
  };

  // Handle Update Roles Button Click
  const handleUpdateClick = (user: any) => {
    setUserToUpdate(user);
    setSelectedRoles(user.roles);
    onUpdateOpen();
  };

  // Submit Updated Roles
  const submitUpdate = () => {
    if (userToUpdate) {
      dispatch(updateUser({ id: userToUpdate.id, roles: selectedRoles }));
      onUpdateClose();
    }
  };

  // Handle Add User Button Click
  const handleAddUserClick = () => {
    setName("");
    setSurname("");
    setEmail("");
    setPassword("");
    setNewUserRoles([]); // Reset roles
    onAddUserOpen();
  };

  // Submit New User
  const handleAddUser = async () => {
    if (name && surname && email && password && newUserRoles.length > 0) {
      const newUser = await dispatch(
        addUser({
          name,
          surname,
          email,
          password,
          roles: newUserRoles, // include selected roles
        })
      );
      setLastAddedUser(newUser);
      onAddUserClose();
    } else {
      dispatch(
        showNotification({
          message: "Please fill out all fields and choose at least one role.",
          type: NotificationType.Error,
        })
      );
    }
  };

  const filteredUsers = users.filter((user) => user.id !== lastAddedUser?.id);

  return (
    <ChakraProvider>
      {basicUserInfo?.roles.some((role: string) => role === Roles.Admin) && <AdminNavbar />}
      <Box p={4}>
        <Flex justifyContent="space-between" alignItems="center">
          <Button colorScheme="teal" onClick={() => navigate(-1)}>
            Back
          </Button>
          <Heading fontSize="2xl" mb={4}>
            User Settings
          </Heading>

          <Button colorScheme="green" mb={4} onClick={handleAddUserClick}>
            Add User
          </Button>
        </Flex>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>Name</Th>
              <Th>Surname</Th>
              <Th>Email</Th>
              <Th>Roles</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredUsers.map((user) => (
              <Tr key={user.id}>
                <Td>{user.id}</Td>
                <Td>{user.name}</Td>
                <Td>{user.surname}</Td>
                <Td>{user.email}</Td>
                <Td>{Array.isArray(user.roles) ? user.roles.join(", ") : "No roles assigned"}</Td>
                <Td>
                  <Button colorScheme="red" size="sm" mr={2} onClick={() => handleDeleteClick(user)}>
                    Delete
                  </Button>
                  <Button colorScheme="blue" size="sm" onClick={() => handleUpdateClick(user)}>
                    Update Roles
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      {/* Add User Modal */}
      <Modal isOpen={isAddUserOpen} onClose={onAddUserClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New User</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <FormControl id="name" isRequired>
                <FormLabel>Name</FormLabel>
                <Input name="name" value={name} onChange={(e) => setName(e.target.value)} autoFocus />
              </FormControl>

              <FormControl id="surname" isRequired>
                <FormLabel>Surname</FormLabel>
                <Input name="surname" value={surname} onChange={(e) => setSurname(e.target.value)} />
              </FormControl>

              <FormControl id="email" isRequired>
                <FormLabel>Email Address</FormLabel>
                <Input name="email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
              </FormControl>

              <FormControl id="password" isRequired>
                <FormLabel>Password</FormLabel>
                <Input name="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
              </FormControl>

              <FormControl id="roles" isRequired>
                <FormLabel>Roles</FormLabel>
                <CheckboxGroup value={newUserRoles} onChange={(values) => setNewUserRoles(values as string[])}>
                  <Stack spacing={2} direction="column">
                    <Checkbox value={Roles.Admin}>ADMIN</Checkbox>
                    <Checkbox value={Roles.User}>USER</Checkbox>
                  </Stack>
                </CheckboxGroup>
              </FormControl>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" onClick={onAddUserClose}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={handleAddUser}>
              Add User
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal isOpen={isDeleteOpen} onClose={onDeleteClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete User</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {userToDelete && <Text>Are you sure you want to delete user with ID: {userToDelete.id}?</Text>}
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onDeleteClose}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={confirmDelete}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Update Roles Modal */}
      <Modal isOpen={isUpdateOpen} onClose={onUpdateClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update User Roles</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {userToUpdate && (
              <Box>
                <Text mb={2}>
                  User: {userToUpdate.name} (ID: {userToUpdate.id})
                </Text>
                <CheckboxGroup value={selectedRoles} onChange={(values) => setSelectedRoles(values as string[])}>
                  <Stack spacing={2} direction="column">
                    <Checkbox value={Roles.Admin}>ADMIN</Checkbox>
                    <Checkbox value={Roles.User}>USER</Checkbox>
                  </Stack>
                </CheckboxGroup>
              </Box>
            )}
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onUpdateClose}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={submitUpdate}>
              Update
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
};

export default UserSettings;
