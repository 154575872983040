import { useEffect } from "react";
import { Box, Button, Heading, Image, ChakraProvider, Flex } from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import { getUser, logout } from "../slices/authSlice";
import { useNavigate } from "react-router-dom";
import { ColorModeSwitcher } from "../ColorModeSwitcher";
import AdminNavbar from "./AdminNavBar";
import { Roles } from "../constants";

const AdminDashboard = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const basicUserInfo = useAppSelector((state) => state.auth.basicUserInfo);
  const userProfileInfo = useAppSelector((state) => state.auth.userProfileData);

  useEffect(() => {
    if (basicUserInfo) {
      dispatch(getUser(basicUserInfo.id));
    }
  }, [basicUserInfo, dispatch]);

  const handleLogout = async () => {
    await dispatch(logout());
    navigate("/login");
  };

  const handleUserActions = () => {
    navigate("/user-actions");
  };

  return (
    <ChakraProvider>
      {basicUserInfo!.roles.some((role: string) => role === Roles.Admin) && <AdminNavbar />}
      <Flex direction="column" p={4}>
        <Flex justifyContent="space-between" alignItems="center" mb={4}>
          <Button colorScheme="teal" onClick={() => navigate(-1)}>
            Back
          </Button>
          <Heading as="h1" size="xl">
            Admin Page
          </Heading>
          <Flex alignItems="center">
            <Heading as="h4" size="md" textAlign="center" mx={4}>
              Welcome: {userProfileInfo?.name}
            </Heading>
            <Button colorScheme="teal" ml={4} onClick={handleLogout}>
              Logout
            </Button>
            <ColorModeSwitcher alignSelf="flex-end" />
          </Flex>
        </Flex>
        <Flex justifyContent="flex-start" mt={8}>
          <Box
            p={4}
            borderWidth={1}
            borderRadius="lg"
            boxShadow="md"
            cursor="pointer"
            onClick={handleUserActions}
            textAlign="center"
            _hover={{ boxShadow: "lg" }}
          >
            <Image
              objectFit="cover"
              src={require("../assets/T_MatchHotel_Logo_00.png")} // Replace with the actual path to your game icon
              alt="Match Hotel"
              boxSize="100px"
              mx="auto"
            />
            <Heading as="h2" size="lg" mt={4}>
              User Actions
            </Heading>
          </Box>
        </Flex>
      </Flex>
    </ChakraProvider>
  );
};

export default AdminDashboard;
