import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Box,
  Button,
  ChakraProvider,
  Flex,
  Heading,
  Input,
  VStack,
  Text,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import { fetchUserActions } from "../slices/userSlice";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import { ColorModeSwitcher } from "../ColorModeSwitcher";
import AdminNavbar from "./AdminNavBar";
import { Roles } from "../constants";
import { useNavigate } from "react-router-dom";
import { getUser, logout } from "../slices/authSlice";

const UserActions = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const basicUserInfo = useAppSelector((state) => state.auth.basicUserInfo);
  const userProfileInfo = useAppSelector((state) => state.auth.userProfileData);

  useEffect(() => {
    if (basicUserInfo) {
      dispatch(getUser(basicUserInfo.id));
    }
  }, [basicUserInfo, dispatch]);

  const handleLogout = async () => {
    await dispatch(logout());
    navigate("/login");
  };

  const [userIdInput, setUserIdInput] = useState<string>(""); // For input field
  const [userId, setUserId] = useState<string>(""); // For search execution
  const [dataActions, setDataActions] = useState<any[]>([]);
  const [userActions, setUserActions] = useState<any[]>([]);
  const [loginActions, setLoginActions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasMoreDataActions, setHasMoreDataActions] = useState<boolean>(true);
  const [hasMoreUserActions, setHasMoreUserActions] = useState<boolean>(true);
  const [hasMoreLoginActions, setHasMoreLoginActions] = useState<boolean>(true);

  // Separate refs for each section
  const dataActionsObserver = useRef<HTMLDivElement>(null);
  const userActionsObserver = useRef<HTMLDivElement>(null);
  const loginActionsObserver = useRef<HTMLDivElement>(null);

  const limit = 10;

  const fetchActions = async (table: string, offset: number, userId: string, setState: any, setHasMore: any) => {
    setLoading(true);

    await dispatch(fetchUserActions({ table, offset, limit, userId })).then((response: any) => {
      const data = response.payload;
      if (data.length < limit) {
        setHasMore(false);
      }

      setState((prevData: any) => [...prevData, ...data]);
    });

    setLoading(false);
  };

  const loadMoreDataActions = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const target = entries[0];
      if (target.isIntersecting && hasMoreDataActions && !loading) {
        fetchActions("data_actions", dataActions.length, userId, setDataActions, setHasMoreDataActions);
      }
    },
    [hasMoreDataActions, loading, dataActions.length, userId]
  );

  const loadMoreUserActions = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const target = entries[0];
      if (target.isIntersecting && hasMoreUserActions && !loading) {
        fetchActions("user_actions", userActions.length, userId, setUserActions, setHasMoreUserActions);
      }
    },
    [hasMoreUserActions, loading, userActions.length, userId]
  );

  const loadMoreLoginActions = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const target = entries[0];
      if (target.isIntersecting && hasMoreLoginActions && !loading) {
        fetchActions("login_actions", loginActions.length, userId, setLoginActions, setHasMoreLoginActions);
      }
    },
    [hasMoreLoginActions, loading, loginActions.length, userId]
  );

  const handleFilter = () => {
    // Update userId to trigger search when the Filter button is pressed
    setUserId(userIdInput);
    setDataActions([]);
    setUserActions([]);
    setLoginActions([]);
    setHasMoreDataActions(true);
    setHasMoreUserActions(true);
    setHasMoreLoginActions(true);
  };

  useEffect(() => {
    fetchActions("data_actions", 0, userId, setDataActions, setHasMoreDataActions);
    fetchActions("user_actions", 0, userId, setUserActions, setHasMoreUserActions);
    fetchActions("login_actions", 0, userId, setLoginActions, setHasMoreLoginActions);
  }, [userId]); // Search happens only when userId is updated

  // Set up Intersection Observers for each section
  useEffect(() => {
    const dataObserver = new IntersectionObserver(loadMoreDataActions);
    if (dataActionsObserver.current) {
      dataObserver.observe(dataActionsObserver.current);
    }

    return () => {
      if (dataActionsObserver.current) dataObserver.disconnect();
    };
  }, [loadMoreDataActions]);

  useEffect(() => {
    const userObserver = new IntersectionObserver(loadMoreUserActions);
    if (userActionsObserver.current) {
      userObserver.observe(userActionsObserver.current);
    }

    return () => {
      if (userActionsObserver.current) userObserver.disconnect();
    };
  }, [loadMoreUserActions]);

  useEffect(() => {
    const loginObserver = new IntersectionObserver(loadMoreLoginActions);
    if (loginActionsObserver.current) {
      loginObserver.observe(loginActionsObserver.current);
    }

    return () => {
      if (loginActionsObserver.current) loginObserver.disconnect();
    };
  }, [loadMoreLoginActions]);

  const [expandedRows, setExpandedRows] = useState<{ [key: number]: boolean }>({});

  const handleRowClick = (index: number) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  return (
    <ChakraProvider>
      {basicUserInfo!.roles.some((role: string) => role === Roles.Admin) && <AdminNavbar />}
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Button colorScheme="teal" onClick={() => navigate(-1)}>
          Back
        </Button>
        <Heading as="h1" size="xl">
          User Actions
        </Heading>
        <Flex alignItems="center">
          <Heading as="h4" size="md" textAlign="center" mx={4}>
            Welcome: {userProfileInfo?.name}
          </Heading>
          <Button colorScheme="teal" ml={4} onClick={handleLogout}>
            Logout
          </Button>
          <ColorModeSwitcher alignSelf="flex-end" />
        </Flex>
      </Flex>
      <VStack spacing={5}>
        <Flex w="30%" mb={4}>
          <Input
            placeholder="Enter User ID to filter (optional)"
            value={userIdInput}
            onChange={(e) => setUserIdInput(e.target.value)} // Input changes don't trigger search
            size="md"
          />
          <Button colorScheme="blue" ml={4} onClick={handleFilter}>
            Search
          </Button>
        </Flex>

        <Tabs isFitted>
          <TabList>
            <Tab>Data Actions</Tab>
            <Tab>User Actions</Tab>
            <Tab>Login Actions</Tab>
          </TabList>

          <TabPanels>
            {/* Data Actions Tab */}
            <TabPanel>
              <Box as="section" w="100%" borderWidth="1px" p={4}>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>CS User ID</Th>
                      <Th>Name</Th>
                      <Th>Surname</Th>
                      <Th>Playfab User ID</Th>
                      <Th>Action</Th>
                      <Th>Old Data</Th>
                      <Th>Updated Data</Th>
                      <Th>Date</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {dataActions.map((action, index) => (
                      <Tr key={index} onClick={() => handleRowClick(index)} cursor="pointer">
                        <Td>{action.csm_user_id}</Td>
                        <Td>{action.name}</Td>
                        <Td>{action.surname}</Td>
                        <Td>{action.playfab_user_id}</Td>
                        <Td>{action.action}</Td>
                        <Td>
                          {expandedRows[index] ? (
                            <pre>{JSON.stringify(JSON.parse(action.old_data), null, 2)}</pre>
                          ) : (
                            <Text>{action.old_data.substring(0, 20)}...</Text>
                          )}
                        </Td>
                        <Td>
                          {expandedRows[index] ? (
                            <pre>{JSON.stringify(JSON.parse(action.new_data), null, 2)}</pre>
                          ) : (
                            <Text>{action.new_data.substring(0, 20)}...</Text>
                          )}
                        </Td>
                        <Td>{action.created_at}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
                {loading && <Spinner />}
                <div ref={dataActionsObserver}></div>
              </Box>
            </TabPanel>

            {/* User Actions Tab */}
            <TabPanel>
              <Box as="section" w="100%" borderWidth="1px" p={4}>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>CS User ID</Th>
                      <Th>Name</Th>
                      <Th>Surname</Th>
                      <Th>Action</Th>
                      <Th>Affected User ID</Th>
                      <Th>Date</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {userActions.map((action, index) => (
                      <Tr key={index}>
                        <Td>{action.csm_user_id}</Td>
                        <Td>{action.name}</Td>
                        <Td>{action.surname}</Td>
                        <Td>{action.action}</Td>
                        <Td>{action.affected_user_id}</Td>
                        <Td>{action.created_at}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
                {loading && <Spinner />}
                <div ref={userActionsObserver}></div>
              </Box>
            </TabPanel>

            {/* Login Actions Tab */}
            <TabPanel>
              <Box as="section" w="100%" borderWidth="1px" p={4}>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>CS User ID</Th>
                      <Th>Name</Th>
                      <Th>Surname</Th>
                      <Th>Action</Th>
                      <Th>Date</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {loginActions.map((action, index) => (
                      <Tr key={index}>
                        <Td>{action.csm_user_id}</Td>
                        <Td>{action.name}</Td>
                        <Td>{action.surname}</Td>
                        <Td>{action.action}</Td>
                        <Td>{action.created_at}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
                {loading && <Spinner />}
                <div ref={loginActionsObserver}></div>
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
    </ChakraProvider>
  );
};

export default UserActions;
