import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../api/axiosInstance";
import { AxiosError } from "axios";
import { ErrorResponse } from "../constants";

type UserBasicInfo = {
  id: string;
  name: string;
  email: string;
  roles: string[];
  token: string;
};

export type GameData = {
  PlayfabId: string;
  Coins: number;
  LazerPowerUp: number;
  ExtraTimePowerUp: number;
  UndoPowerUp: number;
  MagnetPowerUp: number;
  ShufflePowerUp: number;
  TimeFreezePowerUp: number;
};

type GameDataApiState = {
  basicUserInfo?: UserBasicInfo | null;
  gameData?: GameData | null;
  status: "idle" | "loading" | "failed";
  error: string | null;
};

const initialState: GameDataApiState = {
  basicUserInfo: localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo") as string) : null,
  gameData: undefined,
  status: "idle",
  error: null,
};

export const getGameData = createAsyncThunk("gameData/getGameData", async (id: string, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(
      `/playfab/getUserData`,
      { PlayfabId: id },
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")!).token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      const errorResponse = error.response.data;

      return rejectWithValue(errorResponse);
    }

    throw error;
  }
});

export const updateGameData = createAsyncThunk(
  "gameData/updateGameData",
  async (data: GameData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("/playfab/updateUserData", data, {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")!).token}`,
        },
      });

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);

const gameDataSlice = createSlice({
  name: "gameData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getGameData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getGameData.fulfilled, (state, action: PayloadAction<GameData>) => {
        state.status = "idle";
        state.gameData = action.payload;
      })
      .addCase(getGameData.rejected, (state, action) => {
        state.status = "failed";
        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message;
        } else {
          state.error = "An error occurred!";
        }
      })
      .addCase(updateGameData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateGameData.fulfilled, (state, action: PayloadAction<GameData>) => {
        state.status = "idle";
        state.gameData = action.payload;
      })
      .addCase(updateGameData.rejected, (state, action) => {
        state.status = "failed";

        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message;
        } else {
          state.error = "An error occurred!";
        }
      });
  },
});

export default gameDataSlice.reducer;
