import { Middleware } from "@reduxjs/toolkit";
import { showNotification, NotificationType } from "../slices/notificationSlice";

export const axiosMiddleware: Middleware =
  ({ dispatch }) =>
  (next) =>
  async (action: any) => {
    if (action.type.endsWith("login/fulfilled")) {
      const successMessage = "Welcome : " + action.payload?.name || "Login successful";

      dispatch(
        showNotification({
          type: NotificationType.Success,
          message: successMessage,
        })
      );
    } else if (action.type.endsWith("updateGameData/fulfilled")) {
      const successMessage = action.payload?.message || "User data updated successfully";

      dispatch(
        showNotification({
          type: NotificationType.Success,
          message: successMessage,
        })
      );
    } else if (action.type.endsWith("fetchUserActions/rejected")) {
      let errorMessage = action.payload?.message || "Fetching user actions failed";
      if (action.payload!.length === 0) {
        errorMessage = "No data found";
      }

      dispatch(
        showNotification({
          type: NotificationType.Error,
          message: errorMessage,
        })
      );
    } else if (action.type.endsWith("getGameData/rejected")) {
      const errorMessage = action.payload?.message || "An error occurred!";

      dispatch(
        showNotification({
          type: NotificationType.Error,
          message: errorMessage,
        })
      );
    } else if (action.type.endsWith("updateUser/pending")) {
      const message = action.payload?.message || "Updateing user role";

      dispatch(
        showNotification({
          type: NotificationType.Info,
          message: message,
        })
      );
    } else if (action.type.endsWith("updateUser/fulfilled")) {
      const message = action.payload?.message || "User role updated successfully";

      dispatch(
        showNotification({
          type: NotificationType.Success,
          message: message,
        })
      );
    } else if (action.type.endsWith("updateUserPassword/rejected")) {
      const errorMessage = action.payload?.message || "Changing password failed";

      dispatch(
        showNotification({
          type: NotificationType.Error,
          message: errorMessage,
        })
      );
    }

    return next(action);
  };
