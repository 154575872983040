import { useState } from "react";
import {
  ChakraProvider,
  Box,
  Text,
  Spacer,
  theme,
  Flex,
  Input,
  VStack,
  Heading,
  Button,
  useToast,
  HStack,
  NumberInput,
  NumberInputField,
  FormControl,
  FormErrorMessage,
  Select,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import {
  Roles,
  StarterPack,
  ChooseOneAll,
  ChooseOneBronze,
  ChooseOneGold,
  ChooseOneSilver,
  DeluxePack,
  LargeCoinPack,
  LegendaryPack,
  OfferRoad1,
  MediumCoinPack,
  MegaCoinPack,
  MegaPack,
  MiniCoinPack,
  OfferRoad2,
  OfferRoad3,
  PremiumPack,
  PrestigePack,
  SmallCoinPack,
  SpecialCoinPack,
  SpecialOffer,
  XLCoinPack,
  BundleIdPackageNameMap,
} from "../constants";
import { ColorModeSwitcher } from "../ColorModeSwitcher";
import AdminNavbar from "./AdminNavBar";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import { logout } from "../slices/authSlice";
import { GameData, getGameData, updateGameData } from "../slices/gameDataSlice";
import { useNavigate } from "react-router-dom";

const MatchHotelDashboard = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const basicUserInfo = useAppSelector((state) => state.auth.basicUserInfo);

  const [userID, setUserID] = useState("");
  const [error, setError] = useState("");
  const [userCoins, setUserCoins] = useState<number | null>(null);
  const [lazerPowerUp, setLazerPowerUp] = useState<number | null>(null);
  const [extraTimePowerUp, setExtraTimePowerUp] = useState<number | null>(null);
  const [undoPowerUp, setUndoPowerUp] = useState<number | null>(null);
  const [magnetPowerUp, setMagnetPowerUp] = useState<number | null>(null);
  const [shufflePowerUp, setShufflePowerUp] = useState<number | null>(null);
  const [timeFreezePowerUp, setTimeFreezePowerUp] = useState<number | null>(null);

  const [baseUserCoins, setBaseUserCoins] = useState<number | null>(null);
  const [baseLazerPowerUp, setBaseLazerPowerUp] = useState<number | null>(null);
  const [baseExtraTimePowerUp, setBaseExtraTimePowerUp] = useState<number | null>(null);
  const [baseUndoPowerUp, setBaseUndoPowerUp] = useState<number | null>(null);
  const [baseMagnetPowerUp, setBaseMagnetPowerUp] = useState<number | null>(null);
  const [baseShufflePowerUp, setBaseShufflePowerUp] = useState<number | null>(null);
  const [baseTimeFreezePowerUp, setBaseTimeFreezePowerUp] = useState<number | null>(null);

  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [isUserIDFormValid, setIsUserIDFormValid] = useState<boolean>(false);
  const [isUserDataFormValid, setIsUserDataFormValid] = useState<boolean>(false);

  const [userCoinInputValue, setUserCoinInputValue] = useState<number | null>(null);
  const [lazerPowerUpInputValue, setLazerPowerUpInputValue] = useState<number | null>(null);
  const [extraTimePowerUpInputValue, setExtraTimePowerUpInputValue] = useState<number | null>(null);
  const [undoPowerUpInputValue, setUndoPowerUpInputValue] = useState<number | null>(null);
  const [magnetPowerUpInputValue, setMagnetPowerUpInputValue] = useState<number | null>(null);
  const [shufflePowerUpInputValue, setShufflePowerUpInputValue] = useState<number | null>(null);
  const [timeFreezePowerUpInputValue, setTimeFreezePowerUpInputValue] = useState<number | null>(null);

  const [isCoinInputValueValid, setIsCoinInputValueValid] = useState<boolean>(false);
  const [isLazerPowerUpInputValueValid, setIsLazerPowerUpInputValueValid] = useState<boolean>(false);
  const [isExtraTimePowerUpInputValueValid, setIsExtraTimePowerUpInputValueValid] = useState<boolean>(false);
  const [isUndoPowerUpInputValueValid, setIsUndoPowerUpInputValueValid] = useState<boolean>(false);
  const [isMagnetPowerUpInputValueValid, setIsMagnetPowerUpInputValueValid] = useState<boolean>(false);
  const [isShufflePowerUpInputValueValid, setIsShufflePowerUpInputValueValid] = useState<boolean>(false);
  const [isTimeFreezePowerUpInputValueValid, setIsTimeFreezePowerUpInputValueValid] = useState<boolean>(false);

  const [selectedPackage, setSelectedPackage] = useState("");

  const [tableData, setTableData] = useState<any[]>([]);
  const toast = useToast();

  const handleLogout = async () => {
    await dispatch(logout());
    navigate("/login");
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toUpperCase();
    const isValid = /^[A-F0-9]*$/.test(value) && value.length > 0;

    if (isValid) {
      setError("");
    } else {
      if (value.length === 0) {
        setError("User ID cannot be empty.");
      } else {
        setError("User ID must only contain uppercase hexadecimal characters (0-9, A-F).");
      }
    }
    setIsUserIDFormValid(isValid);
    setUserID(value);
  };

  const fetchUserData = async () => {
    setIsFetching(true); // Disable the button while fetching
    setIsUserDataFormValid(false);
    setTableData([]);

    try {
      toast.closeAll();
      toast({
        title: "Fetching user data",
        description: "Please wait",
        status: "info",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
      // Set base values
      setBaseUserCoins(null);
      setBaseLazerPowerUp(null);
      setBaseExtraTimePowerUp(null);
      setBaseUndoPowerUp(null);
      setBaseMagnetPowerUp(null);
      setBaseShufflePowerUp(null);
      setBaseTimeFreezePowerUp(null);
      // Set display values
      setUserCoins(null);
      setLazerPowerUp(null);
      setExtraTimePowerUp(null);
      setUndoPowerUp(null);
      setMagnetPowerUp(null);
      setShufflePowerUp(null);
      setTimeFreezePowerUp(null);

      await dispatch(getGameData(userID))
        .then((res) => {
          const userData = res.payload.data.Data;
          const boosterData = JSON.parse(userData.Boosters.Value);
          const powerUpsData = JSON.parse(userData.PowerUps.Value);
          const coinsData = JSON.parse(userData.PlayerCurrency.Value);
          const inAppPurchasesData = JSON.parse(userData.InAppPurchase.Value);

          // Simulate fetching data and updating state
          const powerUps = {
            coins: coinsData.CurrentGold,
            lazerPowerUp: boosterData.LaserCount,
            extraTimePowerUp: boosterData.ExtraTimeCount,
            undoPowerUp: powerUpsData.UndoPowerUp,
            magnetPowerUp: powerUpsData.MagnetPowerUp,
            shufflePowerUp: powerUpsData.ShufflePowerUp,
            timeFreezePowerUp: powerUpsData.TimeFreezePowerUp,
          };

          // Set base values
          setBaseUserCoins(powerUps.coins);
          setBaseLazerPowerUp(powerUps.lazerPowerUp);
          setBaseExtraTimePowerUp(powerUps.extraTimePowerUp);
          setBaseUndoPowerUp(powerUps.undoPowerUp);
          setBaseMagnetPowerUp(powerUps.magnetPowerUp);
          setBaseShufflePowerUp(powerUps.shufflePowerUp);
          setBaseTimeFreezePowerUp(powerUps.timeFreezePowerUp);
          // Set display values
          setUserCoins(powerUps.coins);
          setLazerPowerUp(powerUps.lazerPowerUp);
          setExtraTimePowerUp(powerUps.extraTimePowerUp);
          setUndoPowerUp(powerUps.undoPowerUp);
          setMagnetPowerUp(powerUps.magnetPowerUp);
          setShufflePowerUp(powerUps.shufflePowerUp);
          setTimeFreezePowerUp(powerUps.timeFreezePowerUp);

          // Set in app purchase data
          let inAppPurchasesTableData = [];
          for (const purchase of inAppPurchasesData.PurchasedItemList) {
            inAppPurchasesTableData.push({
              bundleId: purchase.Id,
              packageName: BundleIdPackageNameMap[purchase.Id as keyof typeof BundleIdPackageNameMap],
              price: purchase.Price,
              currency: purchase.PriceCurrencyCode,
              date: new Date(purchase.Date * 1000).toUTCString(),
            });
          }

          setIsUserDataFormValid(true);
          setIsFetching(false);
          setTableData(inAppPurchasesTableData);
          setSelectedPackage("");

          toast({
            title: "User found",
            description: "User data has been fetched successfully",
            status: "success",
            position: "top",
            duration: 3000,
            isClosable: true,
          });
        })
        .catch((err) => {
          setIsFetching(false);

          // Set base values
          setBaseUserCoins(null);
          setBaseLazerPowerUp(null);
          setBaseExtraTimePowerUp(null);
          setBaseUndoPowerUp(null);
          setBaseMagnetPowerUp(null);
          setBaseShufflePowerUp(null);
          setBaseTimeFreezePowerUp(null);
          // Set display values
          setUserCoins(null);
          setLazerPowerUp(null);
          setExtraTimePowerUp(null);
          setUndoPowerUp(null);
          setMagnetPowerUp(null);
          setShufflePowerUp(null);
          setTimeFreezePowerUp(null);
        });
    } catch (error) {
      console.error("Error fetching user coins:", error);
    }
  };

  const updateUserDataFields = (
    userDataField: number | null, // Current data value
    setDataField: React.Dispatch<React.SetStateAction<number | null>>, // Setter for data value
    setBaseDataField: React.Dispatch<React.SetStateAction<number | null>>, // Setter for base data value
    inputValue: number | null, // Input value entered by the user
    operation: "increment" | "decrement" // Operation to be performed
  ) => {
    if (userDataField !== null && inputValue !== null) {
      if (operation === "increment") {
        setDataField(userDataField + inputValue);
        setBaseDataField(userDataField + inputValue);
      } else if (operation === "decrement") {
        setDataField(Math.max(userDataField - inputValue, 0)); // Prevent going below 0
        setBaseDataField(Math.max(userDataField - inputValue, 0)); // Prevent going below 0
      }
    }
  };

  // Handle input value change for user data fields with use states
  const handleUserDataFieldsValueChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setInputValue: React.Dispatch<React.SetStateAction<number | null>>,
    setIsInputValid: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    const value = e.target.value;
    if (/^[1-9]\d*$/.test(value)) {
      setInputValue(parseInt(value, 10));
      setIsInputValid(true);
    } else {
      setInputValue(null);
      setIsInputValid(false);
    }
  };

  const updateUserData = async () => {
    setIsUserDataFormValid(false);
    setIsFetching(true);

    toast.closeAll();
    toast({
      title: "Updating user data",
      description: "Please wait",
      status: "info",
      position: "top",
      duration: 3000,
      isClosable: true,
    });

    const data: GameData = {
      PlayfabId: userID,
      Coins: userCoins!,
      LazerPowerUp: lazerPowerUp!,
      ExtraTimePowerUp: extraTimePowerUp!,
      UndoPowerUp: undoPowerUp!,
      MagnetPowerUp: magnetPowerUp!,
      ShufflePowerUp: shufflePowerUp!,
      TimeFreezePowerUp: timeFreezePowerUp!,
    };

    await dispatch(updateGameData(data as GameData)).then(() => {
      setIsUserDataFormValid(true);
      setIsFetching(false);
      setSelectedPackage("");
    });
  };

  const handleUserDataSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    // Handle form submission
  };

  const inAppPurchases = [
    SpecialOffer,
    StarterPack,
    PremiumPack,
    MegaPack,
    DeluxePack,
    PrestigePack,
    LegendaryPack,
    SpecialCoinPack,
    MiniCoinPack,
    SmallCoinPack,
    MediumCoinPack,
    LargeCoinPack,
    XLCoinPack,
    MegaCoinPack,
    OfferRoad1,
    OfferRoad2,
    OfferRoad3,
    ChooseOneBronze,
    ChooseOneSilver,
    ChooseOneGold,
    ChooseOneAll,
  ];

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedItem = inAppPurchases.find((item) => item.name === e.target.value);
    setSelectedPackage(e.target.value);

    if (!selectedItem) {
      // Reset to base values if "Select a package" is chosen or no package is selected
      setUserCoins(baseUserCoins);
      setLazerPowerUp(baseLazerPowerUp);
      setExtraTimePowerUp(baseExtraTimePowerUp);
      setUndoPowerUp(baseUndoPowerUp);
      setMagnetPowerUp(baseMagnetPowerUp);
      setShufflePowerUp(baseShufflePowerUp);
      setTimeFreezePowerUp(baseTimeFreezePowerUp);
    } else {
      // Update displayed values based on selected package
      setUserCoins(baseUserCoins! + (selectedItem.coins || 0));
      setLazerPowerUp(baseLazerPowerUp! + (selectedItem.lazerPowerUp || 0));
      setExtraTimePowerUp(baseExtraTimePowerUp! + (selectedItem.extraTimePowerUp || 0));
      setUndoPowerUp(baseUndoPowerUp! + (selectedItem.undoPowerUp || 0));
      setMagnetPowerUp(baseMagnetPowerUp! + (selectedItem.magnetPowerUp || 0));
      setShufflePowerUp(baseShufflePowerUp! + (selectedItem.shufflePowerUp || 0));
      setTimeFreezePowerUp(baseTimeFreezePowerUp! + (selectedItem.timeFreezePowerUp || 0));
    }
  };

  return (
    <ChakraProvider theme={theme}>
      {basicUserInfo?.roles.some((role: string) => role === Roles.Admin) && <AdminNavbar />}
      <Flex justifyContent="space-between" alignItems="center" p={4}>
        <Button colorScheme="teal" onClick={() => navigate(-1)}>
          Back
        </Button>

        <Flex alignItems="center">
          <Button colorScheme="teal" ml={4} onClick={handleLogout}>
            Logout
          </Button>
          <ColorModeSwitcher alignSelf="flex-end" />
        </Flex>
      </Flex>

      <Heading textAlign="center" fontSize="xx-large" key="header_text">
        Customer Dashboard
      </Heading>

      <HStack spacing={4} key="header_buttons" marginTop="40px">
        <VStack alignItems="center" maxWidth={450} spacing={1}>
          <Flex alignItems={"center"} gap={4} maxWidth={450}>
            <Box p="5" key="userIDBox" w="150px">
              <Text as="b" key="userIDText">
                User ID
              </Text>
            </Box>
            <FormControl isInvalid={!!error}>
              <Input placeholder="Enter User ID..." value={userID} onChange={handleInputChange} />
              {error && <FormErrorMessage>{error}</FormErrorMessage>}
            </FormControl>
          </Flex>
          <Flex justifyContent="center" gap={4} w="100%">
            <Button
              marginLeft={"50px"}
              colorScheme="blue"
              size="lg"
              key="getUserDataButton"
              onClick={fetchUserData}
              isDisabled={isFetching || !isUserIDFormValid}
            >
              Get User Data
            </Button>
          </Flex>
        </VStack>

        <VStack alignItems="center" maxWidth={800} spacing={1} marginLeft={"50px"}>
          <Flex alignItems={"center"} alignSelf="flex-end" gap={4} w="100%">
            <Heading size="md" key="powerUpsHeader" w="230px" marginLeft={"20px"}>
              Power Up
            </Heading>
            <Heading size="md" key="currentValueHeader" w="150px">
              PowerUp Value
            </Heading>
          </Flex>
          <form onSubmit={handleUserDataSubmit} style={{ width: "100%" }}>
            <Flex alignItems={"center"} alignSelf="flex-end" gap={4} w="100%">
              <Box p="5" key="userCoinBox" w="500px">
                <Text as="b" key="userCoinsText">
                  User Coins
                </Text>
              </Box>
              <Spacer />
              <NumberInput
                isReadOnly={true}
                variant={"filled"}
                value={userCoins !== null ? userCoins : ""}
                onChange={(valueString) => setUserCoins(parseInt(valueString, 10))}
              >
                <NumberInputField
                  name="userCoinsInput"
                  w="240px"
                  placeholder="User Coins..."
                  key="userCoinsInput"
                  required
                />
              </NumberInput>

              <FormControl>
                <HStack>
                  <Button
                    colorScheme="red"
                    isDisabled={!isCoinInputValueValid || userCoins === 0} // Disable if no valid input or coins are 0
                    onClick={() =>
                      updateUserDataFields(userCoins, setUserCoins, setBaseUserCoins, userCoinInputValue, "decrement")
                    }
                  >
                    -
                  </Button>
                  <Input
                    type="number"
                    placeholder="..."
                    value={userCoinInputValue !== null ? userCoinInputValue : ""}
                    onChange={(e) =>
                      handleUserDataFieldsValueChange(e, setUserCoinInputValue, setIsCoinInputValueValid)
                    }
                    w="70px"
                    min={1}
                  />
                  <Button
                    colorScheme="green"
                    isDisabled={!isCoinInputValueValid} // Disable if no valid input
                    onClick={() =>
                      updateUserDataFields(userCoins, setUserCoins, setBaseUserCoins, userCoinInputValue, "increment")
                    }
                  >
                    +
                  </Button>
                </HStack>
              </FormControl>
            </Flex>
            <Flex alignItems={"center"} alignSelf="flex-end" gap={4} w="100%">
              <Box p="5" key="lazerPowerUpBox" w="500px">
                <Text as="b" key="lazerPowerUpText">
                  Lazer PowerUp
                </Text>
              </Box>
              <Spacer />
              <NumberInput
                isReadOnly={true}
                variant={"filled"}
                value={lazerPowerUp !== null ? lazerPowerUp : ""}
                onChange={(valueString) => setLazerPowerUp(parseInt(valueString, 10))}
              >
                <NumberInputField
                  name="lazerPowerUpInput"
                  w="240px"
                  placeholder="Lazer Power Up..."
                  key="lazerPowerUpInput"
                  required
                />
              </NumberInput>

              <FormControl>
                <HStack>
                  <Button
                    colorScheme="red"
                    isDisabled={!isLazerPowerUpInputValueValid || lazerPowerUp === 0} // Disable if no valid input or coins are 0
                    onClick={() =>
                      updateUserDataFields(
                        lazerPowerUp,
                        setLazerPowerUp,
                        setBaseLazerPowerUp,
                        lazerPowerUpInputValue,
                        "decrement"
                      )
                    }
                  >
                    -
                  </Button>
                  <Input
                    type="number"
                    placeholder="..."
                    value={lazerPowerUpInputValue !== null ? lazerPowerUpInputValue : ""}
                    onChange={(e) =>
                      handleUserDataFieldsValueChange(e, setLazerPowerUpInputValue, setIsLazerPowerUpInputValueValid)
                    }
                    w="70px"
                    min={1}
                  />
                  <Button
                    colorScheme="green"
                    isDisabled={!isLazerPowerUpInputValueValid} // Disable if no valid input
                    onClick={() =>
                      updateUserDataFields(
                        lazerPowerUp,
                        setLazerPowerUp,
                        setBaseLazerPowerUp,
                        lazerPowerUpInputValue,
                        "increment"
                      )
                    }
                  >
                    +
                  </Button>
                </HStack>
              </FormControl>
            </Flex>
            <Flex alignItems={"center"} alignSelf="flex-end" gap={4} w="100%">
              <Box p="5" key="extraTimePowerUpBox" w="500px">
                <Text as="b" key="extraTimePowerUpText">
                  ExtraTime PowerUp
                </Text>
              </Box>
              <Spacer />
              <NumberInput
                isReadOnly={true}
                variant={"filled"}
                value={extraTimePowerUp !== null ? extraTimePowerUp : ""}
                onChange={(valueString) => setExtraTimePowerUp(parseInt(valueString, 10))}
              >
                <NumberInputField
                  name="extraTimePowerUpInput"
                  w="240px"
                  placeholder="Extra Time Power Up..."
                  key="extraTimePowerUpInput"
                  required
                />
              </NumberInput>

              <FormControl>
                <HStack>
                  <Button
                    colorScheme="red"
                    isDisabled={!isExtraTimePowerUpInputValueValid || extraTimePowerUp === 0} // Disable if no valid input or coins are 0
                    onClick={() =>
                      updateUserDataFields(
                        extraTimePowerUp,
                        setExtraTimePowerUp,
                        setBaseExtraTimePowerUp,
                        extraTimePowerUpInputValue,
                        "decrement"
                      )
                    }
                  >
                    -
                  </Button>
                  <Input
                    type="number"
                    placeholder="..."
                    value={extraTimePowerUpInputValue !== null ? extraTimePowerUpInputValue : ""}
                    onChange={(e) =>
                      handleUserDataFieldsValueChange(
                        e,
                        setExtraTimePowerUpInputValue,
                        setIsExtraTimePowerUpInputValueValid
                      )
                    }
                    w="70px"
                    min={1}
                  />
                  <Button
                    colorScheme="green"
                    isDisabled={!isExtraTimePowerUpInputValueValid} // Disable if no valid input
                    onClick={() =>
                      updateUserDataFields(
                        extraTimePowerUp,
                        setExtraTimePowerUp,
                        setBaseExtraTimePowerUp,
                        extraTimePowerUpInputValue,
                        "increment"
                      )
                    }
                  >
                    +
                  </Button>
                </HStack>
              </FormControl>
            </Flex>
            <Flex alignItems={"center"} alignSelf="flex-end" gap={4} w="100%">
              <Box p="5" key="undoPowerUpBox" w="500px">
                <Text as="b" key="undoPowerUpText">
                  Undo PowerUp
                </Text>
              </Box>
              <Spacer />
              <NumberInput
                isReadOnly={true}
                variant={"filled"}
                value={undoPowerUp !== null ? undoPowerUp : ""}
                onChange={(valueString) => setUndoPowerUp(parseInt(valueString, 10))}
              >
                <NumberInputField
                  name="undoPowerUpInput"
                  w="240px"
                  placeholder="Undo Power Up..."
                  key="undoPowerUpInput"
                  required
                />
              </NumberInput>

              <FormControl>
                <HStack>
                  <Button
                    colorScheme="red"
                    isDisabled={!isUndoPowerUpInputValueValid || undoPowerUp === 0} // Disable if no valid input or coins are 0
                    onClick={() =>
                      updateUserDataFields(
                        undoPowerUp,
                        setUndoPowerUp,
                        setBaseUndoPowerUp,
                        undoPowerUpInputValue,
                        "decrement"
                      )
                    }
                  >
                    -
                  </Button>
                  <Input
                    type="number"
                    placeholder="..."
                    value={undoPowerUpInputValue !== null ? undoPowerUpInputValue : ""}
                    onChange={(e) =>
                      handleUserDataFieldsValueChange(e, setUndoPowerUpInputValue, setIsUndoPowerUpInputValueValid)
                    }
                    w="70px"
                    min={1}
                  />
                  <Button
                    colorScheme="green"
                    isDisabled={!isUndoPowerUpInputValueValid} // Disable if no valid input
                    onClick={() =>
                      updateUserDataFields(
                        undoPowerUp,
                        setUndoPowerUp,
                        setBaseUndoPowerUp,
                        undoPowerUpInputValue,
                        "increment"
                      )
                    }
                  >
                    +
                  </Button>
                </HStack>
              </FormControl>
            </Flex>
            <Flex alignItems={"center"} alignSelf="flex-end" gap={4} w="100%">
              <Box p="5" key="magnetPowerUpBox" w="500px">
                <Text as="b" key="magnetPowerUpText">
                  Magnet PowerUp
                </Text>
              </Box>
              <Spacer />
              <NumberInput
                isReadOnly={true}
                variant={"filled"}
                value={magnetPowerUp !== null ? magnetPowerUp : ""}
                onChange={(valueString) => setMagnetPowerUp(parseInt(valueString, 10))}
              >
                <NumberInputField
                  name="magnetPowerUpInput"
                  w="240px"
                  placeholder="Magnet Power Up..."
                  key="magnetPowerUpInput"
                  required
                />
              </NumberInput>

              <FormControl>
                <HStack>
                  <Button
                    colorScheme="red"
                    isDisabled={!isMagnetPowerUpInputValueValid || magnetPowerUp === 0} // Disable if no valid input or coins are 0
                    onClick={() =>
                      updateUserDataFields(
                        magnetPowerUp,
                        setMagnetPowerUp,
                        setBaseMagnetPowerUp,
                        magnetPowerUpInputValue,
                        "decrement"
                      )
                    }
                  >
                    -
                  </Button>
                  <Input
                    type="number"
                    placeholder="..."
                    value={magnetPowerUpInputValue !== null ? magnetPowerUpInputValue : ""}
                    onChange={(e) =>
                      handleUserDataFieldsValueChange(e, setMagnetPowerUpInputValue, setIsMagnetPowerUpInputValueValid)
                    }
                    w="70px"
                    min={1}
                  />
                  <Button
                    colorScheme="green"
                    isDisabled={!isMagnetPowerUpInputValueValid} // Disable if no valid input
                    onClick={() =>
                      updateUserDataFields(
                        magnetPowerUp,
                        setMagnetPowerUp,
                        setBaseMagnetPowerUp,
                        magnetPowerUpInputValue,
                        "increment"
                      )
                    }
                  >
                    +
                  </Button>
                </HStack>
              </FormControl>
            </Flex>
            <Flex alignItems={"center"} alignSelf="flex-end" gap={4} w="100%">
              <Box p="5" key="shufflePowerUpBox" w="500px">
                <Text as="b" key="shufflePowerUpText">
                  Shuffle PowerUp
                </Text>
              </Box>
              <Spacer />
              <NumberInput
                isReadOnly={true}
                variant={"filled"}
                value={shufflePowerUp !== null ? shufflePowerUp : ""}
                onChange={(valueString) => setShufflePowerUp(parseInt(valueString, 10))}
              >
                <NumberInputField
                  name="shufflePowerUpInput"
                  w="240px"
                  placeholder="Shuffle Power Up..."
                  key="shufflePowerUpInput"
                  required
                />
              </NumberInput>

              <FormControl>
                <HStack>
                  <Button
                    colorScheme="red"
                    isDisabled={!isShufflePowerUpInputValueValid || shufflePowerUp === 0} // Disable if no valid input or coins are 0
                    onClick={() =>
                      updateUserDataFields(
                        shufflePowerUp,
                        setShufflePowerUp,
                        setBaseShufflePowerUp,
                        shufflePowerUpInputValue,
                        "decrement"
                      )
                    }
                  >
                    -
                  </Button>
                  <Input
                    type="number"
                    placeholder="..."
                    value={shufflePowerUpInputValue !== null ? shufflePowerUpInputValue : ""}
                    onChange={(e) =>
                      handleUserDataFieldsValueChange(
                        e,
                        setShufflePowerUpInputValue,
                        setIsShufflePowerUpInputValueValid
                      )
                    }
                    w="70px"
                    min={1}
                  />
                  <Button
                    colorScheme="green"
                    isDisabled={!isShufflePowerUpInputValueValid} // Disable if no valid input
                    onClick={() =>
                      updateUserDataFields(
                        shufflePowerUp,
                        setShufflePowerUp,
                        setBaseShufflePowerUp,
                        shufflePowerUpInputValue,
                        "increment"
                      )
                    }
                  >
                    +
                  </Button>
                </HStack>
              </FormControl>
            </Flex>
            <Flex alignItems={"center"} alignSelf="flex-end" gap={4} w="100%">
              <Box p="5" key="timeFreezePowerUpBox" w="500px">
                <Text as="b" key="timeFreezePowerUpText">
                  Time Freeze PowerUp
                </Text>
              </Box>
              <Spacer />

              <NumberInput
                isReadOnly={true}
                variant={"filled"}
                value={timeFreezePowerUp !== null ? timeFreezePowerUp : ""}
                onChange={(valueString) => setTimeFreezePowerUp(parseInt(valueString, 10))}
              >
                <NumberInputField
                  name="timeFreezePowerUpInput"
                  w="240px"
                  placeholder="Time Freeze Power Up..."
                  key="timeFreezePowerUpInput"
                  required
                />
              </NumberInput>

              <FormControl>
                <HStack>
                  <Button
                    colorScheme="red"
                    isDisabled={!isTimeFreezePowerUpInputValueValid || timeFreezePowerUp === 0} // Disable if no valid input or coins are 0
                    onClick={() =>
                      updateUserDataFields(
                        timeFreezePowerUp,
                        setTimeFreezePowerUp,
                        setBaseTimeFreezePowerUp,
                        timeFreezePowerUpInputValue,
                        "decrement"
                      )
                    }
                  >
                    -
                  </Button>
                  <Input
                    type="number"
                    placeholder="..."
                    value={timeFreezePowerUpInputValue !== null ? timeFreezePowerUpInputValue : ""}
                    onChange={(e) =>
                      handleUserDataFieldsValueChange(
                        e,
                        setTimeFreezePowerUpInputValue,
                        setIsTimeFreezePowerUpInputValueValid
                      )
                    }
                    w="70px"
                    min={1}
                  />
                  <Button
                    colorScheme="green"
                    isDisabled={!isTimeFreezePowerUpInputValueValid} // Disable if no valid input
                    onClick={() =>
                      updateUserDataFields(
                        timeFreezePowerUp,
                        setTimeFreezePowerUp,
                        setBaseTimeFreezePowerUp,
                        timeFreezePowerUpInputValue,
                        "increment"
                      )
                    }
                  >
                    +
                  </Button>
                </HStack>
              </FormControl>
            </Flex>
            <Flex justifyContent="center" gap={4} w="100%">
              <Button
                w={"150px"}
                marginTop={"20px"}
                name="updateButton"
                colorScheme="blue"
                size="lg"
                key="updateButton"
                onClick={updateUserData}
                isDisabled={!isUserDataFormValid}
              >
                Update
              </Button>
            </Flex>
          </form>
        </VStack>

        <VStack alignItems="center" maxWidth={550} spacing={4}>
          <Select
            maxWidth={300}
            name="selectPackage"
            placeholder="Select a package"
            onChange={handleSelectChange}
            value={selectedPackage}
            isDisabled={!isUserDataFormValid}
          >
            {inAppPurchases.map((item, index) => (
              <option key={index} value={item.name}>
                {item.name} - ${item.price}
              </option>
            ))}
          </Select>
          <Spacer />
          <Spacer />
          <Flex alignItems={"center"} maxWidth={500}>
            <Heading size="md" key="powerUpsHeader" w="350px">
              User's Previous In App Purchases
            </Heading>
          </Flex>
          <TableContainer maxHeight="300px" overflowY="auto">
            <Table>
              <Thead>
                <Tr>
                  <Th>Package Name</Th>
                  <Th>Price</Th>
                  <Th>Currency</Th>
                  <Th>Date</Th>
                </Tr>
              </Thead>
              <Tbody>
                {tableData.map((item, index) => (
                  <Tr key={index}>
                    <Td>{item.packageName}</Td>
                    <Td>{item.price}</Td>
                    <Td>{item.currency}</Td>
                    <Td>{item.date}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          <Flex alignItems={"center"} maxWidth={500}>
            <Heading size="md" w="350px" key="totalSpendingText">
              User's Total Spending : ~{" "}
              {tableData.reduce(
                (acc, item) => Math.round(inAppPurchases.find((p) => p.bundleId === item.bundleId)?.price + acc),
                0
              )}{" "}
              {"USD"}
            </Heading>
          </Flex>
        </VStack>
      </HStack>
    </ChakraProvider>
  );
};

export default MatchHotelDashboard;
