import React from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "./components/HomePage";
import LoginPage from "./components/LoginPage";
import MatchHotelDashboard from "./components/MatchHotelDashboard";
import DefaultLayout from "./layouts/DefaultLayout";
import ProtectedLayout from "./layouts/ProtectedLayout";
import NotificationBar from "./components/notification/NotificationBar";
import { Roles } from "./constants";
import UserSettings from "./components/UserSettings";
import NotFound from "./components/NotFound";
import AdminDashboard from "./components/AdminDashboard";
import UserActions from "./components/UserActions";

function App() {
  return (
    <>
      <NotificationBar />
      <Routes>
        <Route element={<DefaultLayout />}>
          <Route path="/login" element={<LoginPage />} />
        </Route>
        <Route element={<ProtectedLayout allowedRoles={[Roles.Admin, Roles.User]} />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/match-hotel" element={<MatchHotelDashboard />} />
        </Route>
        <Route element={<ProtectedLayout allowedRoles={[Roles.Admin]} />}>
          <Route path="/user-settings" element={<UserSettings />} />
          <Route path="/admin-dashboard" element={<AdminDashboard />} />
          <Route path="/user-actions" element={<UserActions />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
