import * as React from "react";
import { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../hooks/redux-hooks";
import { hideNotification } from "../../slices/notificationSlice";
import { useToast } from "@chakra-ui/react";

const NotificationBar: React.FC = () => {
  const dispatch = useAppDispatch();
  const { open, message, type } = useAppSelector((state) => state.notification);
  const toast = useToast();

  useEffect(() => {
    if (open) {
      toast({
        title: type.charAt(0).toUpperCase() + type.slice(1),
        description: message,
        status: type, // "success", "error", "warning", "info"
        duration: 3000,
        isClosable: true,
        position: "top",
        onCloseComplete: () => dispatch(hideNotification()),
      });
    }
  }, [open, message, type, toast, dispatch]);

  return null; // No need to render anything as the toast handles the notification UI
};

export default NotificationBar;
